import { ISideBarModel } from '../@types';

export const MEMBERS_TYPE = [
    {
        id: 1,
        name: 'សមាជិកទាំងអស់',
    },
    {
        id: 2,
        name: 'ស្នើសុំបង្កើតកាត',
    }
]

export const SIDE_BAR: ISideBarModel[] = [
    {
        id: 1,
        name: 'សមាជិកទាំងអស់',
        routeUrl: '/approved',
        iconUrl: '/assets/icons/users.svg',
    },
    {
        id: 2,
        name: 'ស្នើសុំបង្កើតកាត',
        routeUrl: '/requested',
        iconUrl: '/assets/icons/users.svg',
    }
]