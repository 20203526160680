import { isEmpty } from 'lodash';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { LOCAL_STORAGE_KEY } from '../../constants/auth';

const PrivateRoute = () => {
  const isAuthenticated = !isEmpty(localStorage.getItem(LOCAL_STORAGE_KEY));

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
