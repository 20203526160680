import { Helmet } from 'react-helmet';

export interface AHelmetProps {
  children: React.ReactNode;
}

function AHelmet({ children }: AHelmetProps) {
  return <Helmet>{children}</Helmet>;
}
export default AHelmet;
