import Header from '../Header';

function Layout({ children }: any) {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <div className="car__parking lg:hidden"></div>
      <div className="max-w-[750px] p-[20px] mx-auto z-20">{children}</div>
    </div>
  );
}

export default Layout;
