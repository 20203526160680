import axios, { AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import * as _ from 'lodash';

import { LOCAL_STORAGE_KEY } from '../../constants/auth';

/**
 * @template T the type of the action's `response` tag.
 */
function sendRequest<T, D = any>(config: AxiosRequestConfig<D>): Promise<T> {
  const defaultHeaders = {
    'x-community-type': 0,
    'x-platform-type': 2,
  };
  if (!config.headers) {
    config.headers = {};
  }

  config.baseURL = process.env.REACT_APP_API_URL || 'http://192.168.1.207:3000';
  config.headers = _.merge(config.headers, defaultHeaders);

  return axios<any, AxiosResponse<T>>(config)
    .then((response: AxiosResponse<T>) => response.data)
    .catch((err) => {
      if (err == null) {
        err.errorCode = -1;
        throw err;
      } else {
        if (err.response) {
          throw err.response.data;
        }
        throw err;
      }
    });
}

/**
 * @template T the type of the action's `response`.
 * @template Q the type of the query's `param` in URL `Optionals`.
 */
export function GET<T, Q = any>(url: string, params?: Q, headers?: RawAxiosRequestHeaders): Promise<T> {
  return sendRequest({ method: 'GET', url, headers, params });
}

/**
 * @template T the type of the action's `response`.
 * @template Q the type of the query's `param` in URL `Optionals`.
 */
export function GETBlobWithToken<T, Q = any>(url: string, params?: Q, headers?: RawAxiosRequestHeaders): Promise<T> {
  headers = {
    Authorization: getAccessToken()
  };
  return sendRequest({ method: 'GET', url, headers, params, responseType: 'blob' });
}

/**
 * @template T the type of the action's `response`.
 * @template Q the type of the query's `param` in URL `Optionals`.
 */
export function GETWithToken<T, Q = any>(url: string, params?: Q, headers: RawAxiosRequestHeaders = {}): Promise<T> {
  headers = {
    Authorization: getAccessToken()
  };
  return sendRequest({ method: 'GET', url, headers, params: params });
}

/**
 * @template T the type of the action's `response` tag.
 * @template B the type of the body's `param` JSON.
 */
export function POST<T, B = any>(url: string, data: B, headers?: RawAxiosRequestHeaders): Promise<T> {
  return sendRequest({ method: 'POST', data, url, headers });
}

/**
 * @template T the type of the action's `response` tag.
 * @template B the type of the body's `param` JSON.
 */
export function PUT<T, B = any>(url: string, data: B, headers?: RawAxiosRequestHeaders): Promise<T> {
  return sendRequest({ method: 'PUT', data, url, headers });
}

/**
 * @template T the type of the action's `response`.
 * @template Q the type of the query's `param` in URL `Optionals`.
 */
export function PUTWithToken<T, Q = any>(url: string, data?: Q, headers: RawAxiosRequestHeaders = {}): Promise<T> {
  headers = {
    Authorization: getAccessToken()
  };
  return sendRequest({ method: 'put', data, url, headers, });
}

/**
 * @template T the type of the action's `response`.
 * @template Q the type of the query's `param` in URL `Optionals`.
 */
export function DeleteWithToken<T, Q = any>(url: string, data?: Q, headers: RawAxiosRequestHeaders = {}): Promise<T> {
  headers = { Authorization: getAccessToken() };
  return sendRequest({ method: 'DELETE', data, url, headers, });
}

/**
 * @template T the type of the action's `response` tag.
 * @template B the type of the body's `param` JSON.
 */
export function DELETE<T, B = any>(url: string, data: B, headers?: RawAxiosRequestHeaders): Promise<T> {
  return sendRequest({ method: 'DELETE', data, url, headers });
}

function getAccessToken(): string {
  const storage = localStorage.getItem(LOCAL_STORAGE_KEY);
  const parser = storage ? JSON.parse(storage) : {};
  return `${parser.type} ${parser.accessToken}`;
}
