import React from 'react';

export interface PopUpProps {
  children?: React.ReactNode;
}

function PopUp(props: PopUpProps) {
  const { children } = props;

  return (
    <div className="fixed top-0 left-0 bg-gray-700/50 w-full h-full z-20">
      <div className="w-[90%] lg:w-[50%] max-h-[90%] h-auto rounded-md bg-white absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex flex-col p-10">
        {children}
      </div>
    </div>
  );
}

export default PopUp;
