export const CARD_TYPE = [
  {
    id: 1,
    name: 'VVIP',
    value: 'VVIP',
    accessible: [1], // Accessible by Positions ID
  },
  {
    id: 2,
    name: 'VIP',
    value: 'VIP',
    accessible: [2, 3, 4, 5, 6, 7], // Accessible by Positions ID

  },
  {
    id: 3,
    name: 'កាតមន្ត្រីទូទៅ',
    value: 'កាតមន្ត្រីទូទៅ',
    accessible: [8, 9, 10, 11, 12], // Accessible by Positions ID
  },
  {
    id: 4,
    name: 'កាតភ្ញៀវ',
    value: 'កាតភ្ញៀវ',
    accessible: [], // Accessible by Positions ID
  },
];

export const CARD_AMOUNT = [
  {
    id: 1,
    cardTypeId: 1,
    name: '៥',
    value: 5,
    accessible: [1], // VVIP only 5 card
  },
  {
    id: 2,
    cardTypeId: 2,
    name: '២',
    value: 2,
    accessible: [2], // VIP only 2 card
  },
  {
    id: 3,
    cardTypeId: 3,
    name: '១',
    value: 1,
    accessible: [3], // កាតមន្ត្រីទូទៅ only 1 card
  },
];

export const CARD_ACCESS_TYPE = [
  {
    id: 1,
    name: 'កាតចំណតរថយន្ត',
    value: 'កាតចំណតរថយន្ត',
    accessible: [1, 2, 3, 4, 5, 6], // POSITION BASE
  },
  {
    id: 2,
    name: 'កាតជណ្តើរយន្ត',
    value: 'កាតជណ្តើរយន្ត',
    accessible: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // POSITION BASE
  },
  {
    id: 3,
    name: 'កាតទាំងពីរប្រភេទ',
    value: 'កាតទាំងពីរប្រភេទ',
    accessible: [1, 2, 3, 4, 5, 6],// POSITION BASE
  },
];

export const REASONS = [
  {
    id: 1,
    name: 'បាត់​កាត',
  },
  {
    id: 2,
    name: 'ខូច​កាត​',
  }
];