/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames';
import { find } from 'lodash';
import React, { useRef } from 'react';
import { Control, Controller } from 'react-hook-form';

import { TypeFormController } from '../../@types';
import { useClickOutSide } from '../../hooks';

export interface DropdownProps {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  items?: ItemList[];
  control?: Control<any>;
  //Events
  onChangeHandler?: (param: DropDownTypeParam) => void;
}

export type DropDownTypeParam = string | number | null;

export interface ItemList extends Object {
  id: number;
  name: string;
  value?: string | number;
}

const DropDownController: React.FC<DropdownProps> = (props: DropdownProps) => {
  const { name, control, disabled = false } = props;
  return (
    <>
      {control && (
        <Controller
          disabled={disabled}
          name={name}
          control={control}
          render={(ctlProps) => <Dropdown {...ctlProps} {...props} />}
        />
      )}
    </>
  );
};

const Dropdown: React.FC<DropdownProps & TypeFormController> = (props) => {
  const dropdownRef = useRef<HTMLFieldSetElement>(null);
  useClickOutSide(dropdownRef, () => setOpen(false));

  const {
    name,
    label,
    placeholder = '',
    items = [],
    disabled = false,
    onChangeHandler,
    field: { onChange, value: fieldValue },
    fieldState: { error },
  } = props;

  const clxDisabled = classNames({
    disabled: disabled,
  });

  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<number>(-1);
  const [labels, setLabels] = React.useState<DropDownTypeParam>(placeholder);

  const toggleContent = () => {
    if (disabled || !items.length) {
      return;
    }
    setOpen(!open);
  };

  const onClickItems = (value: ItemList) => {
    setSelectedId(value.id);
    if (onChangeHandler) {
      onChangeHandler(value.id);
    }
    onChange(value.id);
  };

  React.useEffect(() => {
    const item = find(items, { id: selectedId });
    setLabels(item?.name ?? placeholder);
    // Emit Value to form and close wrapper
    setOpen(false);
    onChange(item?.id ?? null);
  }, [selectedId]);

  React.useEffect(() => {
    setSelectedId(fieldValue);
  }, [fieldValue]);

  return (
    <fieldset
      ref={dropdownRef}
      className="flex flex-wrap mx-3 relative"
      name={name}
    >
      {label && (
        <label className="w-full block form-label mb-3 text-sm" htmlFor={name}>
          {label}
        </label>
      )}
      <div
        onClick={toggleContent}
        className={classNames(
          clxDisabled,
          'input-dropdown flex justify-between form-item py-2 px-4 mb-7'
        )}
      >
        <div>{labels}</div>
        {!disabled && (
          <img
            src="/assets/icons/arrow-down.svg"
            alt="/assets/icons/arrow-down.svg"
            width={19}
            height={19}
          />
        )}
      </div>
      {error?.message && (
        <div className="absolute bottom-[5px] form-label mt-1 text-red-500">
          {error.message}
        </div>
      )}
      {open && (
        <ul className="z-10 origin-top-right top-[65%] absolute right-0 w-full mt-3 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 transition duration-300 ease-in-out [&:not(:first-child)]:border-b border-b-[#DBDADE]">
          {items.length > 0 &&
            items.map((item) => (
              <li
                className="divide-gray-100 form-label cursor-pointer px-4 py-2 hover:bg-[#f3f4f6]"
                key={item.id}
                onClick={() => onClickItems(item)}
              >
                {item.name}
              </li>
            ))}
        </ul>
      )}
    </fieldset>
  );
};

export default DropDownController;
