export const REQUEST_STATE = [
  {
    id: 1,
    name: 'រង់ចាំពិនិត្យ',
  },
  {
    id: 2,
    name: 'យល់ព្រម',
  },
  {
    id: 3,
    name: 'បដិសេធន៍',
  },
]