/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames';
import { chain } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Pagination } from '../../../models';

interface PaginationPropsComponent extends Pagination {
  onChange?: (currentPage: number) => void;
}

const PaginationComponent: React.FC<PaginationPropsComponent> = (props) => {
  const { totalPages, currentPage, onChange } = props;
  const maxVisibleButtons = 5; // Number of page buttons to display
  //
  const [idx, setIdx] = useState(currentPage);
  const [items, setItems] = useState<number[]>([]);

  useEffect(() => {
    handlePagination();
  }, [totalPages]);

  useEffect(() => {
    setIdx(currentPage ?? 1);
  }, [currentPage]);

  useEffect(() => {
    handlePagination();
    if (onChange) {
      onChange(idx - 1);
    }
  }, [idx]);

  function handlePagination(): void {
    const arrays = Array.from({ length: totalPages }, (_, i) => i + 1);
    const items = chain(arrays)
      .chunk(maxVisibleButtons)
      .filter((ids: number[]) => ids.includes(idx))
      .get(0, [])
      .value();

    setItems(items);
  }

  const onClickItem = (item: number) => {
    setIdx(item);
  };

  const onClickNext = () => {
    if (idx < totalPages) {
      setIdx(idx + 1);
    }
  };

  const onClickEnd = () => {
    setIdx(totalPages);
  };

  const onClickFirst = () => {
    setIdx(1);
  };

  const onClickPrev = () => {
    if (idx > 1) {
      setIdx(idx - 1);
    }
  };

  const activeSelect = (item: number) =>
    classNames({
      'bg-[#005C94] text-white': idx === item,
      'bg-[#4B465C14]': idx !== item,
    });
  const disabled = (isDisabled: boolean) =>
    classNames({ 'bg-disabled': isDisabled });

  return (
    <div className="flex flex-wrap gap-2 items-center text-[#4B465C]">
      <button
        onClick={onClickFirst}
        className={classNames(
          disabled(idx === 1),
          'px-3 py-1 bg-[#4B465C14] rounded-md'
        )}
      >
        First
      </button>
      <button
        onClick={onClickPrev}
        className={classNames(
          disabled(idx <= 1),
          'px-3 py-1 bg-[#4B465C14] rounded-md'
        )}
      >
        Prev
      </button>
      {items.map((item, index) => (
        <div
          onClick={() => onClickItem(item)}
          className={classNames(
            activeSelect(item),
            'px-3 py-1 cursor-pointer rounded-md transition-all'
          )}
          key={index}
        >
          <span>{item}</span>
        </div>
      ))}
      <button
        onClick={onClickNext}
        className={classNames(
          disabled(idx >= totalPages),
          'px-3 py-1 bg-[#4B465C14] rounded-md'
        )}
      >
        Next
      </button>
      <button
        onClick={onClickEnd}
        className={classNames(
          disabled(idx === totalPages),
          'px-3 py-1 bg-[#4B465C14] rounded-md'
        )}
      >
        End
      </button>
    </div>
  );
};

export default PaginationComponent;
