import { DeleteWithToken, GETBlobWithToken, GETWithToken, POST, PUTWithToken } from '../core';
import {
  FormRegisterResponse, IMember, ParkingSpotFilterForm, ParkingSpots, ParkingSpotsList,
  RequestParkingSpotForm
} from '../models';

export function createRequestParkingSpace(formValue: RequestParkingSpotForm): Promise<FormRegisterResponse> {
  return POST<FormRegisterResponse>('/api/parking-spots/v1/request', formValue);
}

export function getAllParkingSpots(param: ParkingSpotFilterForm): Promise<ParkingSpotsList> {
  return GETWithToken<ParkingSpotsList>('/api/parking-spots/v1/list', param);
}

export function updateParkingSpots(id: string, param: ParkingSpots): Promise<any> {
  return PUTWithToken<any>(`/api/parking-spots/v1/${id}/update`, param);
}

export function rejectTheRequest(id: string): Promise<any> {
  return PUTWithToken<any>(`/api/parking-spots/v1/${id}/reject`);
}

export function removeTheApproveRequest(id: string): Promise<any> {
  return DeleteWithToken<any>(`/api/parking-spots/v1/${id}`);
}

export function approvalTheRequest(id: string, bodyParams: any): Promise<any> {
  return PUTWithToken<any>(`/api/parking-spots/v1/${id}/approve`, bodyParams);
}

export function createValidationOnIDNumber(idNumber: string) {
  return POST<IMember>('api/parking-spots/v1/validate', { idNumber });
}

export function createValidationByFullName(fullName: string) {
  return POST<IMember>('api/parking-spots/v1/validate', { fullName });
}

export function downloadAsExcel(param: ParkingSpotFilterForm) {
  return GETBlobWithToken<Blob>('api/parking-spots/v1/download-excel', param);
}