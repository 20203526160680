/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import { uploadFiles } from '../../../services';
import { TypeFormController } from '../../@types';

export interface FileUploadProps {
  name: string;
  label: string;
  folderName?: string;
  placeholder?: string;
  required?: boolean;
  control?: Control<any>;
}

const FileUploadController: React.FC<FileUploadProps> = (props) => {
  const { name, control } = props;
  return (
    <>
      {control && (
        <Controller
          name={name}
          control={control}
          render={(ctlProps) => <FileUpload {...ctlProps} {...props} />}
        />
      )}
    </>
  );
};

const FileUpload: React.FC<FileUploadProps & TypeFormController> = (props) => {
  const {
    name,
    label,
    folderName,
    field: { onChange, value: fieldValue },
    fieldState: { error },
  } = props;
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFilesUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length) {
      setFile(droppedFiles[0]);
      onSetFileUrl(droppedFiles[0]);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggedOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggedOver(false);
  };

  const onClickDragDrop = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFiles = e.target.files;
    if (inputFiles?.length) {
      onSetFileUrl(inputFiles[0]);
      setFile(inputFiles[0]);
    }
  };

  const onSetFileUrl = (file: File) => {
    setFilesUrl(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (file) {
      const data = new FormData();
      data.append('file', file);
      if (folderName) {
        data.append('folderName', folderName);
      }
      data.append('name', file.name);
      uploadFiles(data).then((res) => {
        onChange(res.fileUrl);
      });
    }
  }, [file]);

  useEffect(() => {
    if (fieldValue && !file) {
      const url = `${process.env.REACT_APP_API_URL}/${fieldValue}`;
      setFilesUrl(url);
    }
  }, [fieldValue]);

  return (
    <div className="flex flex-wrap mx-3 mb-5 mt-5">
      <label className="form-label mb-3 text-sm" htmlFor={name}>
        {label}
      </label>
      <div
        className={classNames('file-upload cursor-pointer p-4', {
          'border-active': isDraggedOver,
          'border-error': !isEmpty(error?.message),
        })}
        onClick={onClickDragDrop}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {fileUrl && (
          <div>
            <img
              className="w-full object-contain"
              style={{ height: '170px' }}
              src={fileUrl}
              alt={fileUrl}
              height={176}
            />
          </div>
        )}
        {!fileUrl && (
          <div className="h-full flex flex-col items-center justify-center gap-4">
            <div className="bg-light-grey p-4 rounded-md">
              <img src="/assets/icons/upload.svg" alt="" />
            </div>
            <h2 className="text-base text-dark-gray-color">
              បង្ហោះរូបភាព
            </h2>
            <p className="form-label">ជ្រើសរើសរូបភាព...</p>
          </div>
        )}
      </div>

      <input
        type="file"
        style={{ display: 'none' }}
        onChange={handleInputChange}
        ref={(input) => (fileInputRef.current = input)}
      />
    </div>
  );
};

export default FileUploadController;
