import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { SIDE_BAR } from '../../../constants';
import { LOCAL_STORAGE_KEY } from '../../../constants/auth';

const SideBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedId, setSelectedId] = useState(-1);

  useEffect(() => {
    const sideBar = SIDE_BAR.find(({ routeUrl }) => pathname === routeUrl);
    setSelectedId(sideBar?.id ?? 1);
  }, [pathname]);

  const onClickLogOut = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    setTimeout(() => {
      navigate('/login');
    }, 500);
  };

  const clxActive = (id: number) =>
    classNames({ 'bg-[#045B99] text-white': selectedId === id });

  return (
    <aside className="w-full h-full bg-white py-5 rounded-md h-full overflow-y-auto dark:bg-gray-8 max-w max-w-[300px]">
      <ul className="mx-4">
        {SIDE_BAR.map((item) => (
          <Link to={item.routeUrl} key={item.id}>
            <li
              className={classNames(
                clxActive(item.id),
                'w-full flex items-center gap-2 p-3 mt-4 hover:bg-slate-200 hover:text-black rounded-md transition-all cursor-pointer'
              )}
            >
              <img src={item.iconUrl} alt="" width={20} />
              <span>{item.name}</span>
            </li>
          </Link>
        ))}
        <li
          className="w-full flex items-center gap-2 p-3 mt-4 hover:bg-slate-200 hover:text-black rounded-md transition-all cursor-pointer"
          onClick={onClickLogOut}
        >
          <img src="/assets/icons/signout.svg" alt="" width={20} />
          <span>ចាកចេញ</span>
        </li>
      </ul>
    </aside>
  );
};

export default SideBar;
