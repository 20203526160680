import { useNavigate } from 'react-router-dom';

type HeaderProps = {
  avatar?: boolean;
};
function Header({ avatar }: HeaderProps) {
  const navigate = useNavigate();

  const onClickLogo = () => {
    navigate('/');
  };

  return (
    <div className="container mx-auto flex justify-between items-center">
      <div
        onClick={onClickLogo}
        className="py-[20px] px-[30px] flex items-center gap-x-[10px] justify-normal cursor-pointer w-full"
      >
        <div className="w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] duration-300">
          <img src="/assets/logo.png" alt="" width={60} height={60} />
        </div>
        <h2 className="text-primary-color text-[9px] lg:text-base">
          អគ្គនាយកដ្ឋាន​បច្ចេកវិទ្យា​ឌីជីថល​ និង​ផ្សព្វផ្សាយ​អប់រំ
          <br />
          <span>General Department of Digital Technology and Media</span>
        </h2>
      </div>
      {avatar && (
        <div className="py-[20px] pr-[30px]">
          <div className="bg-[#045B99] rounded-full">
            <img src="/avatar.png" alt="" width={35} className="rounded-full" />
          </div>
        </div>
      )}
    </div>
  );
}
export default Header;
