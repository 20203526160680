import convertToKhmerNumber from '../../helper/ConvertToKhmerNumeral';

function Footer() {
  const year = convertToKhmerNumber(new Date().getFullYear());
  return (
    <div className="w-[100%] z-20 mx-auto bg-white py-[10px]">
      <p className="text-center text-[10px] text-primary-color drop-shadow-sm">
        ឆ្នាំ {year} © រក្សាសិទ្ធិគ្រប់យ៉ាងដោយ <br className="lg:hidden" />{' '}
        អគ្គនាយកដ្ឋាន​បច្ចេកវិទ្យា​ឌីជីថល និង​ផ្សព្វផ្សាយ​អប់រំ
      </p>
    </div>
  );
}

export default Footer;
