import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import convertToKhmerNumber from '../../helper/ConvertToKhmerNumeral';
import Header from '../Header';
import SideBar from '../SideBar';

const AdminLayout = () => {
  const year = convertToKhmerNumber(new Date().getFullYear());

  useEffect(() => {
    document.body.classList.add('body-bg');
    return () => {
      document.body.classList.remove('body-bg');
    };
  }, []);

  return (
    <>
      <div className="bg-white">
        <Header />
      </div>
      <main className="flex w-full gap-x-[50px] mt-10 container mx-auto">
        <SideBar />
        <section className="w-full bg-white p-6 m space-y-6 rounded-md overflow-scroll">
          <Outlet />
        </section>
      </main>
      <footer className="text-[#1B2B69] text-xs text-center w-full col-span-1 pt-[20px]">
        ឆ្នាំ {year} © រក្សាសិទ្ធិគ្រប់យ៉ាងដោយ
        <br className="lg:hidden" />
        អគ្គនាយកដ្ឋាន​បច្ចេកវិទ្យា​ឌីជីថល​ និង​ផ្សព្វផ្សាយ​អប់រំ
      </footer>
    </>
  );
};

export default AdminLayout;
