import { ToastContainer } from 'react-toastify';

import { AHelmet } from './core';
import ApplicationRoutes from './routes';

function App() {
  return (
    <>
      <AHelmet>
        <title>ទីស្តីការក្រសួងមហាផ្ទៃ</title>
        <meta name="theme-color" content="#000000" />
        <meta name="author" content="MOI Cambodia" />
        <meta
          name="keywords"
          content="MOI, Ministry of Interior, Cambodian Report"
        />
        <meta
          name="description"
          content="MOI Cambodia, Ministry of Interior, Ministry News, Ministry Documents, Cambodia Law, Ministry of Interior Departments, Government New Technology"
        />
      </AHelmet>
      <ApplicationRoutes />
      <ToastContainer
        limit={1}
        position="top-center"
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </>
  );
}

export default App;
