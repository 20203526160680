import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AdminLayout } from '../../core';

const LazyRequestList = lazy(() => import('../../pages/requested-list/'));
const LazyApprovedList = lazy(() => import('../../pages/approved-list/'));

function Containers() {
  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route path="/requested" element={<LazyRequestList />} />
        <Route path="/approved" element={<LazyApprovedList />} />
      </Route>
    </Routes>
  );
}

export default Containers;
