import React, { HTMLInputTypeAttribute } from 'react';
import { Control, Controller } from 'react-hook-form';

import { TypeFormController } from '../../@types';

export interface InputTypeProps {
  name: string;
  label: string;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  control?: Control<any>;
  onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputController: React.FC<InputTypeProps> = (props) => {
  const { name, control } = props;

  return (
    <>
      {control && (
        <Controller
          name={name}
          defaultValue=""
          control={control}
          render={(ctlProps) => <InputText {...ctlProps} {...props} />}
        />
      )}
    </>
  );
};

const InputText: React.FC<InputTypeProps & TypeFormController> = (props) => {
  const {
    name,
    type = 'text',
    label,
    placeholder,
    field,
    fieldState: { error },
    onChangeHandler,
  } = props;

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    if (onChangeHandler) {
      onChangeHandler(e);
    }
  };

  return (
    <fieldset
      className="relative flex justify-start flex-wrap mx-3 "
      name={name}
    >
      {label && (
        <label className="w-full block form-label mb-3 text-sm" htmlFor={name}>
          {label}
        </label>
      )}
      <input
        {...field}
        value={field.value ?? ''}
        className="form-item py-3 px-4 text-xs mb-7"
        type={type}
        onChange={onChangeText}
        placeholder={placeholder}
      />
      {error?.message && (
        <div className="absolute bottom-[6px] form-label mt-1 text-red-500">
          {error.message}
        </div>
      )}
    </fieldset>
  );
};

export default InputController;
