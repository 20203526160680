import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Containers from './containers';
import PrivateRoute from './private';

const LazyHomePage = lazy(() => import('../pages/home'));
const LazyRegistrationPage = lazy(() => import('../pages/registrations'));
const LazyLoginPage = lazy(() => import('../pages/login'));
const LazyAdditionCardPage = lazy(
  () => import('../pages/additional-card-form')
);

const ApplicationRoutes = () => {
  return (
    <Suspense>
      <Router>
        <React.Fragment>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="/*" element={<Containers />}></Route>
            </Route>
            <Route path="/" element={<LazyHomePage />} />
            <Route path="/login" element={<LazyLoginPage />} />
            <Route
              path="/registrations-form"
              element={<LazyRegistrationPage />}
            />
            <Route
              path="/additional-card-form"
              element={<LazyAdditionCardPage />}
            />
          </Routes>
        </React.Fragment>
      </Router>
    </Suspense>
  );
};

export default ApplicationRoutes;
