import { ListType } from '../@types';

export const POSITIONS: ListType[] = [
  {
    id: 1,
    name: 'រដ្ឋមន្ត្រីក្រសួងមហាផ្ទៃ',
    value: "រដ្ឋមន្ត្រីក្រសួងមហាផ្ទៃ",
  },
  {
    id: 2,
    name: 'រដ្ឋលេខាធិការ',
    value: "រដ្ឋលេខាធិការ",
  },
  {
    id: 3,
    name: 'អនុរដ្ឋលេខាធិការ',
    value: "អនុរដ្ឋលេខាធិការ",
  },
  {
    id: 4,
    name: 'អគ្គនាយក',
    value: "អគ្គនាយក",
  },
  {
    id: 5,
    name: 'អគ្គនាយករង',
    value: 'អគ្គនាយករង',
  },
  {
    id: 6,
    name: 'ប្រធាននាយកដ្ឋាន',
    value: 'ប្រធាននាយកដ្ឋាន',
  },
  {
    id: 7,
    name: 'អនុប្រធាននាយកដ្ឋាន',
    value: 'អនុប្រធាននាយកដ្ឋាន',
  },
  {
    id: 8,
    name: 'នាយការិយាល័យ',
    value: 'នាយការិយាល័យ',
  },
  {
    id: 9,
    name: 'នាយរងការិយាល័យ',
    value: 'នាយរងការិយាល័យ',
  },
  {
    id: 10,
    name: 'នាយផ្នែក​',
    value: 'នាយផ្នែក​',
  },
  {
    id: 11,
    name: 'នាយរង​ផ្នែក​',
    value: 'នាយរង​ផ្នែក​',
  },
  {
    id: 12,
    name: 'មន្ត្រី',
    value: 'មន្ត្រី',
  },
];